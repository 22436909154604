@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap);
body{
	margin: 0;
	font-family: 'Source Code Pro', monospace;	
}

.top-pane {
	background-color: hsl(225, 6%, 25%);
}

.pane {
	height: 50vh;
	display: flex;
}

.editor-container {
	flex-grow: 1;
	flex-basis: 0;
	display: flex;
	flex-direction: column;
	padding: 0.5rem;
	background-color: hsl(225, 6%, 25%);
}

.editor-container.collapsed {
	flex-grow: 0;
}

.editor-container.collapsed .CodeMirror-scroll {
	position: absolute;
	overflow: hidden;
}

.editor-title {
	display: flex;
	justify-content: space-between;
	background-color: hsl(225, 6%, 13%);
	color: white;
	padding: 0.5rem 0.5rem 0.5rem 1rem;
	border-top-right-radius: 0.5rem;
	border-top-left-radius: 0.5rem;
}

.code-mirror-wrapper {
	flex-grow: 1;
	border-bottom-right-radius: 0.5rem;
	border-bottom-left-radius: 0.5rem;
	overflow: hidden;
}

.CodeMirror {
	height: 100% !important;
}

.expand-collapse-btn {
	margin-left: 0.5rem;
	cursor: pointer;
	border: none;
	background: none;
	color: white;
}

